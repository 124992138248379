<template>
    <div id="pageTable">     
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol == 'empleado' || rol == 'admin-depto'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>   
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Años fiscales'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        :filters="filters"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" v-if="permisos.create">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Año"
                                    :items="itemsAnios"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="anio_id"
                                    class="pa-0 ma-0">
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                ></v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" :sm="rol == 'root'?'6':'12'" :md="rol == 'root'?'6':'12'" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                ></v-autocomplete>
                            </v-col>
                            <v-col xs="12" sm="12" md="12">
                                <div class="RangoIngreso">
                                    <span>Rango de fechas</span>
                                </div>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_inicio_filter"
                                    label="Fecha inicio"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaInicioFilter"
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_final_filter"
                                    label="Fecha final"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaFinalFilter"
                                />
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data" :class="item.actual?'anio_actual':''">
                                <td>
                                    <div v-if="item.cliente != null" class="tblPrincipal">{{ item.cliente.nombre}}</div>
                                    <div v-if="item.empresa != null">{{ item.empresa.nombre }}</div>
                                </td>
                                <td><div>{{item.anio != null ?item.anio.nombre:""}}</div></td>
                                <td><div>{{dateFormat(item.fecha_inicio)}}</div></td>
                                <td><div>{{dateFormat(item.fecha_final)}}</div></td>
                                <td><div>{{item.cerrado?"Cerrado":"Abierto"}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <template  v-if="
                                                    item.valor !== 'tipo_incidencia_vacaciones' &&
                                                    item.valor !== 'tipo_incidencia_retardo_justificado' &&
                                                    item.valor !== 'tipo_incidencia_incapacidad'"
                                        >
                                            <v-tooltip bottom v-if="item.cerrado && !item.actual">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirAnio(item)">
                                                        <v-icon class="tamIconoBoton invertirColorBotones">play_arrow</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Poner año como actual</span>
                                            </v-tooltip>
                                            <v-tooltip bottom v-if="!item.cerrado">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                        <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Editar</span>
                                            </v-tooltip>
                                            <template v-if="permisos.delete">
                                                <v-tooltip bottom v-if="!item.cerrado">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Eliminar</span>
                                                </v-tooltip>
                                            </template>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="600px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="loading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!loading" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="9" md="9" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="anio" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Año"
                                                    :items="itemsAnios"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="anio_fiscal.anio_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors">
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="3" xs="3" sm="3" md="3">
                                                <v-switch class="pa-0 ma-0 " v-model="anio_fiscal.actual" label="Actual"></v-switch>
                                        </v-col>
                                        <template v-if="rol == 'root'">
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Cliente"
                                                        :items="clientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="cliente_value"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                        </template>
                                        <template v-if="rol == 'root' || rol == 'admin'">
                                            <v-col cols="12" xs="12" :sm="rol == 'root'?'6':'12'" :md="rol == 'root'?'6':'12'" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Empresa"
                                                        :items="empresas"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="empresa_value"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                        </template>
                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                v-model="anio_fiscal.fecha_inicio"
                                                label="Inicia:"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaInicio"
                                            />
                                        </v-col>
                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                v-model="anio_fiscal.fecha_final"
                                                label="Termina:"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaFinal"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <v-textarea
                                                outlined
                                                label="Descripción"
                                                v-model="anio_fiscal.descripcion"
                                            ></v-textarea>
                                        </v-col>
                                        <v-col v-if="accion==='update'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <v-btn class="btnNormal" @click="cerrarAnio()" :loading="isClosing">Cerrar año fiscal</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid || isClosing" 
                                    :loading="isSaving"
                                    v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>

                            <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                                <v-alert
                                    dense
                                    outlined
                                    type="error"
                                    
                                >
                                    El usuario no tiene permiso para editar el registro.
                                </v-alert>
                            </div>

                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/AnualFiscal';
import queriesAnio from '@/queries/Anios';
import Notify from '@/plugins/notify';
import clienteAPi from '@/api/clientes';
import empresaApi from '@/api/empresas';
import catalogosApi from "../api/catalogos";
import anioFiscalApi from "../api/anual_fiscal";
import gFunctions from '@/js/global_functions';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';

export default {
    components: {
        VuetifyDatePicker,
        'data-table': Datatable,
    },
    data() {
        return {
            columns         : [
                { 
                    label: "Cliente", 
                    name: "cliente" 
                },
                {
                    label: "Nombre",
                    name: "nombre",
                    filterable: true,
                },
                {
                    label: 'Inicio',
                    name: 'estatus',
                    filterable: true
                },
                {
                    label: "Fin",
                    name: "periodo",
                    filterable: true,
                },
                {
                    label: "Estatus",
                    name: "cerrado",
                    filterable: true,
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {AND:[]},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            anio_fiscal       : {
                id            : 0,
                cliente_id    : null,
                empresa_id    : null,
                anio_id       : "",
                descripcion   : null,
                fecha_inicio  : null,
                fecha_final   : null,
                actual        : false,
                cerrado       : false,
                fecha_cierre  : null

            },
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            loading         : false,
            isSaving        : false,
            isClosing       : false,
            imagen          : '',
            rol             : null,
            datosLogin      : null,
            clientes        : [],
            empresas        : [],
            cliente_value   : null,
            empresa_value   : null,
            colores         : [],
            anio_id         : null,
            fecha_inicio    : null,
            fecha_final     : null,
            itemsAnios          : [],
            fecha_inicio_filter : null,
            fecha_final_filter  : null,
            permisos :{
                create: false,
                update: false,
                delete: false,
            }
            
        }
    },
    watch: {
        cliente_value: function(val){
            if(val != null){
                this.cambiaCliente(val);
            }
        },
    },
    methods: {
        /**
         * @method dateFormat Esta método parsea una fecha
         * @description Convierte una fecha de formato AAAA-MM-DD => DD/MM/AAAA. Se usa para el modal y la tabla 
         * @param {string} fecha formato AAAA-MM-DD
         * @return {string} devuelve un string en formato DD/MM/AAAA
        */
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },

        /**
         * @method getPaginationInfo Este método se utiliza para la paginación de la tabla.
         * @description Este método se utiliza para la paginación de la tabla con la implementacion de GrapQL.
         * Se ejecuta como emit del componente tabla.
         * @param {Object} data objeto con datos para el número de la página y cuantos elementos en la página 
         */
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }

            //this.$apollo.queries.anios_fiscales;
        },

        /**
         * @method abrirModal Este método realiza la acción para abrir un modal.
         * @description Este método realiza la acción para abrir un modal dependiento de la acción que se le envie, puede ser 'add' o 'update'.
         * @param {string} accion Esta variable puete tomar el valor de 'add' o 'update'.
         * @param {Object} data Esta variable es un objeto, son los datos de un registro año fiscal.
        */
        async abrirModal(accion, data = null){
            await this.resetValues();
            this.$apollo.queries.anios.skip = false;
            this.accion     = accion;
            this.loading  = true;
            
            if(accion == "add"){
                this.tituloModal = "Registrar año fiscal.";
                this.imagen     = "/static/modal/actualizar_incidencia.svg";
                
            } else {
                this.tituloModal = "Actualizar año fiscal.";
                this.imagen     = "/static/modal/actualizar_incidencia.svg";
                this.anio_fiscal.id          = data.id;
                this.anio_fiscal.anio_id      = parseInt(data.anio_id);
                this.anio_fiscal.actual    = data.actual
                this.anio_fiscal.cliente_id  = data.cliente_id;
                this.cliente_value          = data.cliente_id;
                this.anio_fiscal.empresa_id  = data.empresa_id;
                this.empresa_value          = data.empresa_id;

                setTimeout(()=>this.$refs.FechaInicio.setDate(data.fecha_inicio),200);
                setTimeout(()=>this.$refs.FechaFinal.setDate(data.fecha_final),200);

                this.anio_fiscal.descripcion = data.descripcion;
            }

            if (this.rol == "root") {
                this.getClientes();
            }
            else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else{
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value =  this.datosLogin.empresa_id;
            }

            this.loading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },

        /**
         * @method loadModalData Este método se ejecuta cuando se abre el modal de busqueda avanzada.
         * @description Este método se ejecuta cuando se abre el modal de busqueda avanzada. Se realizan las peticiones para llenar los campos que se requieran dependiendo del rol.
         * Se ejecuta como emit del componente tabla.
         */
        async loadModalData() {
            await this.resetValues();
            this.$apollo.queries.anios_fiscales.skip = true;
            this.fecha_inicio_filter = null;
            this.fecha_final_filter = null;

            if(this.$refs.FechaInicioFilter != undefined || this.$refs.FechaInicioFilter != null){
                this.$refs.FechaInicioFilter.resetDate();
            }
            if(this.$refs.FechaFinalFilter != undefined || this.$refs.FechaFinalFilter != null){
                this.$refs.FechaFinalFilter.resetDate();
            }


            if (this.rol == "root") {
                this.getClientes();
            } else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            } else {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
        },

        /**
         * @method setFilters Este método se ejecuta despues de dar clic en el botón buscar
         * @description Este método se ejecuta despues de dar clic en el botón buscar, se envian los filtros que se indicaron en el modal búsqueda. 
         * Y se reflejan en la tabla principal.Se ejecuta como emit del componente tabla.
         */
        setFilters() {
            this.filters = {AND:[]};
            let anio_id = this.anio_id;
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let fecha_inicio = this.fecha_inicio_filter;
            let fecha_final = this.fecha_final_filter;
            if (this.rol == "root") {

            }
            else if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } 
            else{
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
            } 
            
            if(anio_id != null && anio_id != undefined && anio_id != ""){
                this.filters.AND.push({column:'ANIO_ID', value:anio_id});
            }
            
            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.AND.push({column:'CLIENTE_ID', value:cliente});
            }

            if(empresa != null && empresa != undefined && empresa != ""){
                this.filters.AND.push({column:'EMPRESA_ID', value:empresa});
            }

            if(fecha_inicio != null && fecha_inicio != undefined && fecha_inicio != ""){
                this.filters.AND.push({column:'FECHA_INICIO',operator: "GTE" , value:fecha_inicio});
            }

            if(fecha_final != null && fecha_final != undefined && fecha_final != ""){
                this.filters.AND.push({column:'FECHA_FINAL',operator: "LTE", value:fecha_final});
            }
            this.paginationData.numberPage = 1;
            this.$apollo.queries.anios_fiscales.skip = false;
            //this.$apollo.queries.anios_fiscales.refetch();
            this.resetValues();
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.isClosing = false;
            this.resetValues();
        },
        resetValues() {
            this.anio_fiscal = {
                id            : 0,
                cliente_id    : null,
                empresa_id    : null,
                anio_id       : null,
                descripcion   : null,
                fecha_inicio  : null,
                fecha_final   : null,
                actual        : false,
                cerrado       : false,
                fecha_cierre : null
            };

            if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                this.$refs.FechaInicio.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }

            this.$nextTick(() => {
               if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }

                if (this.rol == "root") {
                    this.cliente_value = null;
                    this.empresa_value = null;
                    this.empresas = [];
                }
    
                if (this.rol == "admin") {
                    this.empresa_value = null;
                }

                this.anio_id = null;
                this.actual = false;
                this.abierto = true;
            });
        },
        eliminar(data){
            if(data.actual){
                Notify.Warning(
                    "ADVERTENCIA",
                    "Operación no permitida, no se puede eliminar el año seleccionado como actual",
                    "Aceptar",
                    () => {
                        
                    }
                )
            }else{
                Notify.Alert(
                    "¿Estás seguro de eliminar este año fiscal?",
                    "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                    "Eliminar",
                    () => {
                        this.isLoading = true;
                        anioFiscalApi.deleteAnio(data.id).then(response => {
                            Notify.Success("Operación exitosa", "El año fiscal ha sido eliminado satisfactoriamente.");
                            this.$apollo.queries.anios_fiscales.refetch();
                        })
                        .catch(err => {
                            this.isLoading = false;
                            gFunctions.errorParse(err.response.data.error);
                        });
                    },
                    null,
                    true,
                    true
                )
            }
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                this.anio_fiscal.cliente_id = this.cliente_value;
                this.anio_fiscal.empresa_id = this.empresa_value;

                this.isLoading = true;
                if(this.accion === "add") {
                    anioFiscalApi.addAnio(this.anio_fiscal)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El año fiscal se guardó satisfactoriamente.");
                        this.$apollo.queries.anios_fiscales.refetch();
                        this.cerrarModal();
                        this.resetValues();
                    })
                    .catch(err => {
                        this.isSaving = false;
                        this.isLoading = false;
                        gFunctions.errorParse(err.response.data.error);
                    });
                }
                else if(this.accion === "update") {
                    anioFiscalApi.updateAnio(this.anio_fiscal)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El año fiscal se actualizó satisfactoriamente.");
                        this.$apollo.queries.anios_fiscales.refetch();
                        this.cerrarModal();
                        this.resetValues();
                    })
                    .catch(err => {
                        this.isSaving = false;
                        this.isLoading = false;
                        gFunctions.errorParse(err.response.data.error);
                    });
                }
            });
        },
        async cerrarAnio(){
            
            await Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas cerrar el año fiscal?",
                "Cerrar",
                () => {
                    this.isClosing = true;
                    this.anio_fiscal.cerrado = true;
                    anioFiscalApi.updateAnio(this.anio_fiscal)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El año fiscal se cerro satisfactoriamente.");
                        this.$apollo.queries.anios_fiscales.refetch();
                        this.cerrarModal();
                        this.resetValues();
                    })
                    .catch(err => {
                        gFunctions.errorParse(err.response.data.error);
                    });
                },
                () =>{
                    this.isClosing = false;
                }
            );
            
        },
        async abrirAnio(anio){
            await Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas poner el año fiscal como actual?",
                "Aceptar",
                () => {
                    anioFiscalApi.actual(anio.id)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El año fiscal se puso como actual.");
                        this.$apollo.queries.anios_fiscales.refetch();
                        this.cerrarModal();
                        this.resetValues();
                    })
                    .catch(err => {
                        gFunctions.errorParse(err.response.data.error);
                    });
                }
            );
        },
        getClientes() {
            let parametros = { activo: true, paginate: false };
            clienteAPi.getClientes(parametros).then((response) => {
                this.clientes = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },
        cambiaCliente(id, empresa = null) {
            if (this.anio_fiscal.cliente_id != id) {
                this.empresa_value = null;
                this.anio_fiscal.empresa_id = null;
            }

            if(id == null){
                return;
            }

            let parametros = { activo: true, paginate: false, cliente_id: id };
            empresaApi.getEmpresas(parametros).then((response) => {
                this.empresas = response.data;
                if (this.accion != "add") {
                    this.anio_fiscal.empresa_id = this.empresa_value;
                }
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
       
        obtenerColor(color_id=null){
            var nombreColor = this.colores.find(element => element.id == color_id);
            return nombreColor == null? "":nombreColor.nombre;
        },
        cargaInicial(){
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            this.filters = {AND:[]};
            
            if (this.rol == "root") {
                this.getClientes();
            }
            else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.filters = { AND:[{column:'CLIENTE_ID', value:this.datosLogin.cliente_id}]};
            }
            else{
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value =  this.datosLogin.empresa_id;
                this.filters.AND.push({column:'CLIENTE_ID', value:this.datosLogin.cliente_id});
                this.filters.AND.push({column:'EMPRESA_ID', value:this.datosLogin.empresa_id});
            }
        },
        permisosVista(){
            let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
            this.permisos.create = permisosStorage.includes("anio-fiscal.store")
            this.permisos.update = permisosStorage.includes("anio-fiscal.update")
            this.permisos.delete = permisosStorage.includes("anio-fiscal.delete")
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
    },
    created() {
        this.cargaInicial();
        this.permisosVista();
    },
     mounted(){
        this.$apollo.queries.anios_fiscales.skip = false;
        this.$apollo.queries.anios.skip = false;
    },
    

    apollo: {
        anios_fiscales: {
            query       : queries.anioFiscalTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading = true;
                return {
                    whereConditions : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'fecha_inicio',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip(){
                return true;
            },
            result({loading, error}) {
                this.isLoading    = false;
                this.tableData  = this.anios_fiscales;
            },
        },
        anios: {
            query       : queriesAnio.anioTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                return {
                    numberItems : 1000000,
                    numberPage  : 1,
                    fieldOrder  : 'nombre',
                }
            },
            skip(){
                return true;
            },
            result({loading, error}) {
                this.itemsAnios = this.anios.data;
            },
        }
    },
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    #contenedor {
        margin-top: -6%;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }

    .anio_actual td{
    /*  color: #002438; */
        color: #24d40d !important;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.34px;
        line-height: 17px;
        text-align: left;
    }
</style>
